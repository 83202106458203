import { Col, Row } from 'antd'
import _ from 'lodash'
import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import CSelect from '../../../../components/Common/CSelect/CSelect'
import { COption } from '../../../../components/Common/CSelect/model/COption'
import Loading from '../../../../components/Common/Loading/Loading'
import GenKeyHook from '../../../../hook/genKeyHook'
import accessoriesApi from '../../../../http/accessoriesApi'
import { ClientCompanyWareHouse } from '../../../../models'
import { generateUUID, removeTones } from '../../../../utils'
import {
  CInput,
  NameAndValue,
} from '../../../stock/stockscreen/component/Wrapper'
import { validateEmail } from '../../../../utils/validate'
import { CommonError } from '../../../../models/common/error'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { MODE } from '../../../../enum/mode'
import {
  linearToNested,
  nestedWasSelectedToLinear,
  tickOnDefaulData,
  tickOnDefaulDataV2,
} from './func'
import { forceDisableOptions } from '../../../../components/Common/CSelect/func/functions'

export interface ClientMainSectionStateInterface {
  nom: string
  code: string
  email: string
  client_company?: ClientCompanyWareHouse[]
  warehouse_client?: ClientCompanyWareHouse[]
  client_warehouse_company_org?: ClientCompanyWareHouse[]
}

interface ClientMainSectionInterface {
  initData?: ClientMainSectionStateInterface
  nonEditData?: ClientCompanyWareHouse[]
  mode?: MODE
  onChangeData?: (newValue: ClientMainSectionStateInterface) => void
  mainSectionError?: CommonError[]
  onCheckValid?: (isValid: boolean) => void
}

const ClientMainSection = (props: ClientMainSectionInterface) => {
  const clientKey = useSelector((state: RootState) => state.selector).data
    .client
  const {
    onChangeData = () => {},
    mainSectionError = [],
    onCheckValid = () => {},
  } = props
  const [formState, setFormState] = useState<ClientMainSectionStateInterface>({
    nom: '',
    code: '',
    email: '',
    client_warehouse_company_org: [],
  })
  const [isValidNom, setIsValidNom] = useState<boolean>(false)
  const [isValidCode, setIsValidCode] = useState<boolean>(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false)
  const [societeEntropotsFromApi, setSocieteEntropotsFromApi] = useState<
    COption[]
  >([])
  const { code } = GenKeyHook()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (props.mode === MODE.EDIT) return
    setFormState({
      ...formState,
      code,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, props.mode])

  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    if (formState.code) {
      setIsValidCode(true)
    } else setIsValidCode(false)
    if (formState.nom) {
      setIsValidNom(true)
    } else setIsValidNom(false)
    if (formState.email) {
      setIsValidEmail(true)
    } else setIsValidEmail(false)
    const isValid = isValidCode && isValidNom && isValidEmail
    onCheckValid(isValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, isValidCode, isValidNom, isValidEmail])

  useEffect(() => {
    setIsLoading(true)
    accessoriesApi
      .getCompanyWarehouse()
      .then((res) => {
        const entry = res.data.entry
        const mode = props.mode
        const initData = props.initData
        if (mode !== MODE.EDIT) {
          let result = linearToNested(entry)
          result = uniqBy(result, 'value')
          setSocieteEntropotsFromApi(result)
        } else {
          if (!initData) return
          const listDisabled = (props?.nonEditData || []).map((item) => [
            item.company_code_nom || '',
            item.warehouse_code_nom || '',
          ])
          let mapData = tickOnDefaulDataV2(
            entry,
            initData.client_warehouse_company_org || []
          )
          forceDisableOptions(mapData, listDisabled)
          mapData = uniqBy(mapData, 'value')
          setSocieteEntropotsFromApi(mapData)
          setFormState(initData)
        }
      })
      .catch(() => {})
      .finally(() => setIsLoading(false))
  }, [props.mode, props.initData, props.nonEditData])

  useEffect(() => {
    const mode = props.mode
    const initData = props.initData
    if (mode !== MODE.EDIT || !initData) return
    setFormState({
      ...formState,
      code: initData.code,
      nom: initData.nom,
      email: initData.email,
    })
  }, [props.mode, props.initData])

  const onChangeInput = ({ name, value }: NameAndValue) => {
    setFormState({
      ...formState,
      [name || '']: value,
    })
  }
  const onChangeSE = (options: COption[]) => {
    let result = nestedWasSelectedToLinear(options)
    let warehouseArr = []
    let companyArr = []
    for (let i = 0; i < result.length; i++) {
      let company_code_nom_res = result[i].company_code_nom
      let warehouse_nom_res = result[i].warehouse_code_nom
      companyArr.push({
        company_code_nom: company_code_nom_res,
      })
      warehouseArr.push({
        warehouse_code_nom: warehouse_nom_res,
      })
    }
    setFormState({
      ...formState,
      warehouse_client: _.uniqBy(warehouseArr, 'warehouse_code_nom'),
      client_company: _.uniqBy(companyArr, 'company_code_nom'),
      client_warehouse_company_org: [...result],
    })
  }

  return (
    <div className="section-layout " style={{ height: 150 }}>
      {isLoading && <Loading />}
      <div className="flex py-5">
        <div className="flex mr-20 items-center">
          <Row>
            <Col span={6}>
              <div className="form-label require-filed mr-5">Nom</div>
            </Col>
            <Col span={18}>
              <CInput
                id={clientKey['nom']}
                nextId={clientKey['code']}
                name="nom"
                value={formState.nom}
                maxLength={32}
                required
                className="custom-input"
                onChange={onChangeInput}
                errorMessageForce={
                  mainSectionError.find((item) => item.name === 'nom')
                    ?.errorMessage
                }
              />
            </Col>
          </Row>
        </div>
        <div className="flex mr-20 items-center">
          <Row>
            <Col span={6}>
              <div className="form-label require-filed mr-5">Code</div>
            </Col>
            <Col span={18}>
              <CInput
                id={clientKey['code']}
                nextId={clientKey['email']}
                previousId={clientKey['nom']}
                name="code"
                value={formState.code}
                maxLength={10}
                required
                className="custom-input"
                format={removeTones}
                onChange={onChangeInput}
                errorMessageForce={
                  mainSectionError.find((item) => item.name === 'code')
                    ?.errorMessage
                }
              />
            </Col>
          </Row>
        </div>
        <div className="flex relative">
          <Row>
            <Col>
              <div className="form-label">Sociétés - Entrepôt(s)</div>
            </Col>
            <Col className="absolute left-48 z-10">
              <CSelect
                id={clientKey['societe-entrepot']}
                previousId={clientKey['email']}
                nextId={clientKey['rupture']}
                width={350}
                optionsAreaMaxHeight={300}
                onChange={onChangeSE}
                options={societeEntropotsFromApi}
                height={100}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="flex mr-20 items-center">
        <Row>
          <Col span={6}>
            <div className="form-label require-filed mr-3.5">Email</div>
          </Col>
          <Col span={18}>
            <CInput
              id={clientKey['email']}
              previousId={clientKey['code']}
              nextId={clientKey['societe-entrepot']}
              name="email"
              placeHolder=""
              maxLength={100}
              className="custom-input w-full"
              required
              value={formState.email}
              validates={[
                {
                  validateFunction: (email) => validateEmail(email),
                  message: 'Entrée Invalide',
                  order: 1,
                  realtime: true,
                },
              ]}
              onChange={onChangeInput}
              errorMessageForce={
                mainSectionError.find((item) => item.name === 'email')
                  ?.errorMessage
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ClientMainSection
