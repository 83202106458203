import { COption } from '../../../../components/Common/CSelect/model/COption'
import {
  Client,
  ClientCompanyWareHouse,
  ClientWarehouseCompany,
} from '../../../../models'
import { generateUUID } from '../../../../utils'
import { deepMergeDiff } from '../../../../utils/objectUtils'

export type DataFromApi = {
  id: string
  create_at: number
  update_at: number
  nom: string
  code: string
  pre_code: string
  code_nom: string
  client_company: ClientCompanyWareHouse[]
  warehouse_client: ClientCompanyWareHouse[]
  email: string
  rupture?: boolean
  alerte_dluo?: string
  fifo?: boolean
  fefo?: boolean
  types_cdn?: boolean
  variantes?: boolean
  mois_dluo_activate?: boolean
  mois_dluo_input?: number
}

export function mapDataFromApiToState(dataFromApi: Client) {
  const mainSectionData = {
    nom: dataFromApi.nom || '',
    code: dataFromApi.code || '',
    email: dataFromApi.email || '',
    client_company: dataFromApi.client_company,
    warehouse_client: dataFromApi.warehouse_client,
    client_warehouse_company_org: dataFromApi.client_warehouse_company_org,
  }

  const gestionData = {
    rupture: dataFromApi.rupture,
    alerte_dluo: String(dataFromApi.alerte_dluo),
    fifo: dataFromApi.fifo,
    fefo: dataFromApi.fefo,
    types_cdn: dataFromApi.types_cdn,
    variantes: dataFromApi.variantes,
    mois_dluo_activate: dataFromApi.mois_dluo_activate,
    mois_dluo_input: dataFromApi.mois_dluo_input,
  }

  return { mainSectionData, gestionData }
}

export function tickOnDefaulData(
  init: ClientCompanyWareHouse[],
  client_companyTicked: ClientCompanyWareHouse[],
  warehouse_clientTicked: ClientCompanyWareHouse[]
) {
  const triangleTickeds = mergeTwoPairsToTriangle(
    client_companyTicked,
    warehouse_clientTicked
  )
  for (let i = 0; i < triangleTickeds.length; i++) {
    const triangleTicked = triangleTickeds[i]
    const triangleInInitData = init.find((item) =>
      compareValueTwoTriangles(item, triangleTicked)
    )
    if (triangleInInitData) continue
    triangleTickeds.splice(i--, 1)
  }

  return linearToNested(init, triangleTickeds)
}

export function tickOnDefaulDataV2(
  init: ClientCompanyWareHouse[],
  tickedTriangle: ClientCompanyWareHouse[]
) {
  return linearToNested(init, tickedTriangle)
}

export function mergeTwoPairsToTriangle(
  client_company: ClientCompanyWareHouse[],
  warehouse_client: ClientCompanyWareHouse[]
) {
  const result: ClientCompanyWareHouse[] = []

  client_company.forEach((cc) => {
    const clientCn = cc.client_code_nom
    const companyCn = cc.company_code_nom
    const wcs = warehouse_client.filter(
      (item) => item.client_code_nom === clientCn
    )
    if (wcs.length === 0) return

    wcs.forEach((wc) => {
      result.push({
        client_code_nom: clientCn,
        warehouse_code_nom: wc.warehouse_code_nom,
        company_code_nom: companyCn,
      })
    })
  })
  return result
}

function compareValueTwoTriangles(
  t1: ClientCompanyWareHouse,
  t2: ClientCompanyWareHouse
) {
  return (
    t1.warehouse_code_nom == t2.warehouse_code_nom &&
    t1.company_code_nom == t2.company_code_nom
  )
}

function compareValueTwoTrianglesWithId(
  t1: ClientCompanyWareHouse,
  t2: ClientCompanyWareHouse
) {
  return (
    compareValueTwoTriangles(t1, t2) &&
    t1.client_code_nom === t2.client_code_nom &&
    t1.id === t2.id
  )
}

export function nestedWasSelectedToLinear(options: COption[]) {
  let result = []
  for (let i = 0; i < options.length; i++) {
    const currentOption = options[i]
    if (!currentOption.selected) continue
    const {
      value: company_id,
      childOptions,
      label: company_code_nom,
    } = currentOption
    for (let j = 0; j < childOptions.length; j++) {
      const currentChildOption = childOptions[j]
      if (!currentChildOption.selected) continue
      const { value: warehouse_id, label: warehouse_code_nom } =
        currentChildOption
      result.push({
        company_id: company_id,
        warehouse_id: warehouse_id,
        company_code_nom: company_code_nom,
        warehouse_code_nom: warehouse_code_nom,
      })
    }
  }

  return result
}

export function linearToNested(
  data: any[],
  tickedData?: ClientCompanyWareHouse[]
) {
  let result: COption[] = []
  for (let i = 0; i < data.length; i++) {
    const currentData = data[i]
    const { company_code_nom, company_id: parentCompanyId } = currentData
    const option: COption = {
      uuid: generateUUID(),
      label: company_code_nom,
      value: parentCompanyId,
      show: true,
      level: 0,
      childOptions: [],
    }
    for (let j = 0; j < data.length; j++) {
      let child = data[j]
      const {
        company_id: childCompanyId,
        warehouse_code_nom,
        warehouse_id,
      } = child
      if (parentCompanyId !== childCompanyId) continue
      let childOption: COption = {
        uuid: generateUUID(),
        label: warehouse_code_nom,
        value: warehouse_id,
        level: 1,
        show: false,
        childOptions: [],
      }
      option.childOptions.push(childOption)
      if (tickedData) {
        const tickedByCompany = tickedData.filter(
          (item) => item.company_id === option.value
        )
        if (tickedByCompany.length > 0) {
          option.selected = true
          option.childOptions.map((child) => {
            if (
              tickedByCompany.find((item) => item.warehouse_id === child.value)
            ) {
              child.selected = true
            }
            return child
          })
        }
      }
    }

    result.push(option)
  }

  return result
}

export function getTheChangeOfClientData(
  initData: Client,
  newData: Client,
  ignorPaths?: string[][]
) {
  return deepMergeDiff(initData, newData, ignorPaths) as Client
}

export function getRemoveTriangles(
  initTriangles: ClientCompanyWareHouse[],
  newTriangles: ClientCompanyWareHouse[]
): string[] {
  const result = []

  for (let i = 0; i < initTriangles.length; i++) {
    const init = initTriangles[i]
    const isExistInNewTriangles = newTriangles.find((newTriangle) =>
      compareValueTwoTriangles(newTriangle, init)
    )
    if (isExistInNewTriangles) continue
    if (!init.id) continue
    result.push(init.id)
  }

  return result
}

export function mapIdToTriangles(
  initTriangles: ClientCompanyWareHouse[],
  newTriangles: ClientCompanyWareHouse[]
) {
  for (let i = 0; i < newTriangles.length; i++) {
    const newTriangle = newTriangles[i]
    const initTriangleCorrespond = initTriangles.find((initTriangle) =>
      compareValueTwoTriangles(initTriangle, newTriangle)
    )
    if (!initTriangleCorrespond) continue
    newTriangle.id = initTriangleCorrespond?.id
  }
  return newTriangles
}
