import { useEffect, useState } from 'react'
import './QSwitch.scss'

export interface QSwitchProps {
  /**
   * indicate switch is disable or not
   */
  disabled?: boolean

  /**
   * default value of QSwitch
   */
  defaultChecked?: boolean

  /**
   * default checked of QSwitch
   */
  checked?: boolean

  /**
   * trigger when state checking change
   */
  onChange?(value: boolean): void

  /**
   * name of QSwitch
   */
  name?: string

  /**
   * to style for QSwitch
   */
  className?: string
  id?: string
  nextId?: string
  previousId?: string
}

/**
 *
 * The @QSwitch is common switch with label
 * @version 0.1
 * @author [anhnq]
 *
 */
const QSwitch = (props: QSwitchProps) => {
  const [checked, setChecked] = useState<boolean>(
    props?.checked || props?.defaultChecked || false
  )

  const onToggle = () => {
    if (props?.disabled) return
    setChecked(!checked)
    if (props?.onChange) {
      props.onChange(!checked)
    }
  }

  useEffect(() => {
    setChecked(Boolean(props?.checked))
  }, [props?.checked])

  return (
    <div
      className="qswitch"
      onClick={onToggle}
      id={props.id}
      data-next-id={props.nextId}
      data-previous-id={props.previousId}
      tabIndex={0}
    >
      <input
        type="checkbox"
        id="togBtn"
        checked={checked}
        disabled={props?.disabled}
        defaultChecked={props?.defaultChecked}
        onChange={() => {}}
      />
      <div className="qslider qround">
        <span className="on">FIFO</span>
        <span className="off">FEFO</span>
      </div>
    </div>
  )
}

export default QSwitch
