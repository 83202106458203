import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import CSwitch from '../../../../components/Common/CSwitch/CSwitch'
import QSwitch from '../../../../components/Common/QSwitch/QSwitch'
import { InputType } from '../../../../enum/InputType'
import { CommonError } from '../../../../models/common/error'
import { isLessThan, removeLeadingZero } from '../../../../utils'
import SectionLayout from '../../../stock/stockscreen/component/SectionLayout'
import {
  CInput,
  NameAndValue,
} from '../../../stock/stockscreen/component/Wrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { MODE } from '../../../../enum/mode'

export interface ClientGestionStateInterface {
  rupture?: boolean
  alerte_dluo?: string
  fifo?: boolean
  fefo?: boolean
  types_cdn?: boolean
  variantes?: boolean
  mois_dluo_activate?: boolean
  mois_dluo_input?: number
}

interface ClientGestionProps {
  disabled?: boolean
  mode?: MODE
  initData?: ClientGestionStateInterface
  /**
   * function update data when gestion data changed
   * @param newValue ClientGestionStateInterface
   * @returns void
   */
  onChangeData?: (newValue: ClientGestionStateInterface) => void
  /**
   * array of gestion errors
   */
  clientGestionError?: CommonError[]
  /**
   * function check valid of gestion section
   * @param isValid
   * @returns void
   */
  onCheckValid?: (isValid: boolean) => void
}

const Gestion = (props: ClientGestionProps) => {
  const clientKey = useSelector((state: RootState) => state.selector).data
    .client
  const {
    onChangeData = () => {},
    clientGestionError = [],
    onCheckValid = () => {},
    disabled = false,
  } = props
  const [formState, setFormState] = useState<ClientGestionStateInterface>({
    rupture: false,
    alerte_dluo: '',
    fifo: false,
    fefo: false,
    types_cdn: false,
    variantes: false,
    mois_dluo_activate: false,
    mois_dluo_input: 0,
  })

  useEffect(() => {
    const { initData, mode } = props
    if (!initData || mode !== MODE.EDIT) return
    setFormState(initData)
  }, [props.initData, props.mode])

  useEffect(() => {
    onChangeData(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    let isValidDLUO = true
    if (formState.mois_dluo_activate) {
      if (!formState.mois_dluo_input) isValidDLUO = false
      else isValidDLUO = true
    }
    onCheckValid(isValidDLUO)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  useEffect(() => {
    setFormState({
      ...formState,
      fifo: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeSwitch = (value: boolean, field: string) => {
    setFormState({
      ...formState,
      [field]: value,
    })
    if (field === 'mois_dluo_activate') {
      if (value) {
        setFormState({
          ...formState,
          mois_dluo_activate: true,
        })
      } else {
        setFormState({
          ...formState,
          mois_dluo_activate: false,
          mois_dluo_input: 0,
        })
      }
    }
  }

  const onChangeQSwitch = (value: boolean) => {
    setFormState({
      ...formState,
      fifo: value,
      fefo: !value,
    })
  }

  const onChangeInput = ({ name, value }: NameAndValue) => {
    setFormState({
      ...formState,
      [name || '']: value,
    })
  }

  return (
    <SectionLayout title="Gestion">
      <form>
        <Row className="mt-3">
          <Col xl={{ span: 3 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Rupture
              </Col>
              <Col xl={{ span: 1 }}>
                <CSwitch
                  id={clientKey['rupture']}
                  previousId={clientKey['societe-entrepot']}
                  nextId={clientKey['fifo-fefo']}
                  name="rupture"
                  onChange={(value) => {
                    onChangeSwitch(value, 'rupture')
                  }}
                  checked={formState.rupture}
                  className="custom-switch"
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 4 }}>
            <Row className="mb-4">
              <Col xl={{ span: 12, offset: 3 }} className="form-label">
                FIFO/FEFO
              </Col>
              <Col xl={{ span: 9 }}>
                <QSwitch
                  id={clientKey['fifo-fefo']}
                  previousId={clientKey['rupture']}
                  nextId={clientKey['mois']}
                  onChange={(value) => {
                    onChangeQSwitch(value)
                  }}
                  checked={formState.fifo}
                  defaultChecked={true}
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 8 }}>
            <Row className="mb-4">
              <Col xl={{ span: 12, offset: 5 }} className="form-label">
                Mois de DLUO restants
              </Col>
              <Col xl={{ span: 7 }}>
                <CSwitch
                  id={clientKey['mois']}
                  previousId={clientKey['fifo-fefo']}
                  nextId={`${clientKey['mois-nbr']}, ${clientKey['variante']}, ${clientKey['Sauvegarder']}`}
                  name="mois_dluo_activate"
                  onChange={(value) => {
                    onChangeSwitch(value, 'mois_dluo_activate')
                  }}
                  checked={formState.mois_dluo_activate}
                  className="custom-switch"
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
          {formState.mois_dluo_activate && (
            <Col xl={{ span: 6 }}>
              <Row className="mb-4">
                <Col xl={{ span: 8, offset: 4 }} className="form-label">
                  Nbr mois
                </Col>
                <Col xl={{ span: 12 }}>
                  <CInput
                    id={clientKey['mois-nbr']}
                    previousId={
                      formState.mois_dluo_activate && clientKey['mois']
                    }
                    nextId={
                      formState.mois_dluo_activate && clientKey['Sauvegarder']
                    }
                    name="mois_dluo_input"
                    value={formState.mois_dluo_input}
                    className="custom-input"
                    maxLength={2}
                    type={[InputType.NUMBER_INTEGER_POS]}
                    format={removeLeadingZero}
                    validates={[
                      {
                        message: 'Nombre de mois ne peut pas dépasser 24',
                        validateFunction: (value) =>
                          isLessThan(Number(value), 25),
                        order: 0,
                        realtime: true,
                      },
                    ]}
                    onChange={onChangeInput}
                    errorMessageForce={
                      clientGestionError.find(
                        (item) => item.name === 'mois_dluo_input'
                      )?.errorMessage
                    }
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="mt-3">
          <Col xl={{ span: 3 }}>
            <Row className="mb-4">
              <Col xl={{ span: 14 }} className="form-label">
                Variantes
              </Col>
              <Col xl={{ span: 10 }}>
                <CSwitch
                  id={clientKey['variante']}
                  previousId={
                    formState.mois_dluo_activate
                      ? clientKey['mois-nbr']
                      : clientKey['mois']
                  }
                  nextId={`${clientKey['cdn']}, ${clientKey['alert-dluo']}, ${clientKey['Sauvegarder']}`}
                  name="variantes"
                  onChange={(value) => {
                    onChangeSwitch(value, 'variantes')
                  }}
                  checked={formState.variantes}
                  className="custom-switch"
                  disabled={disabled}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={{ span: 4 }}>
            <Row className="mb-4">
              <Col xl={{ span: 12, offset: 3 }} className="form-label">
                Types Cdn
              </Col>
              <Col xl={{ span: 9 }}>
                <CSwitch
                  id={clientKey['cdn']}
                  // previousId={clientKey['variante']}
                  // nextId={clientKey['alert-dluo']}
                  name="types_cdn"
                  onChange={(value) => {
                    onChangeSwitch(value, 'types_cdn')
                  }}
                  checked={formState.types_cdn}
                  className="custom-switch"
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xl={{ span: 8 }}>
            <Row className="mb-4">
              <Col xl={{ span: 11 }} className="form-label">
                Alerte DLUO (jours)
              </Col>
              <Col xl={{ span: 9 }}>
                <CInput
                  id={clientKey['alert-dluo']}
                  // previousId={clientKey['cdn']}
                  // nextId={clientKey['Sauvegarder']}
                  name="alerte_dluo"
                  value={formState.alerte_dluo}
                  maxLength={3}
                  required
                  className="custom-input"
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </SectionLayout>
  )
}

export default Gestion
