import { ClientCompanyArrayInterface } from '../models'
import baseAxios from './clientAPI'

const accessoriesApi = {
  getCompanyWarehouse(): Promise<ClientCompanyArrayInterface> {
    const url = '/warehouse/accessories/company-warehouse'
    return baseAxios.get(url)
  },

  getCompanyWarehouseByClientId(
    clientId: string,
    params: { is_block: boolean }
  ): Promise<ClientCompanyArrayInterface> {
    const { is_block } = params
    const url = `/warehouse/accessories/company-warehouse/${localStorage.getItem(
      'username'
    )}/${clientId}?is_block=${is_block}`
    return baseAxios.get(url)
  },
}

export default accessoriesApi
